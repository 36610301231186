const UNIVERSAL = {
  // BASEURL: "https://8a67-45-114-49-199.ngrok-free.app/",
  // BASEURL: "https://skyyriderelectric.azurewebsites.net/",
  // BASEURL: "https://employee-backend-7h6ce756aa-el.a.run.app/",
  // BASEURL:"http://34.93.96.107:3004/",
  // BASEURL:"https://34.131.149.186:3000/",
  // BASEURL: "http://34.93.117.25:3004/",
  BASEURL:"https://k.kidtryz.com/",
  BASEPROFILE:"http://35.200.161.163:3004/"
  
};
export default UNIVERSAL;
