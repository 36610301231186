import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography, Box } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { green, red } from '@mui/material/colors';

const PaymentPopups = () => {
  const [successOpen, setSuccessOpen] = useState(false);
  const [failOpen, setFailOpen] = useState(true);

  const handleSuccessOpen = () => {
    setSuccessOpen(true);
  };

  const handleFailOpen = () => {
    setFailOpen(true);
  };

  const handleClose = () => {
    setSuccessOpen(false);
    setFailOpen(false);
  };

  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Payment Status
      </Typography>

      {/* Buttons to trigger the popups */}
      <Button
        variant="contained"
        color="success"
        onClick={handleSuccessOpen}
        style={{ margin: '10px', padding: '10px 20px', fontSize: '16px' }}
      >
        Show Payment Success
      </Button>
      <Button
        variant="contained"
        color="error"
        onClick={handleFailOpen}
        style={{ margin: '10px', padding: '10px 20px', fontSize: '16px' }}
      >
        Show Payment Failed
      </Button>

      {/* Payment Success Dialog */}
      <Dialog open={successOpen} onClose={handleClose}>
        <DialogTitle>
          <Box display="flex" justifyContent="center">
            <CheckCircleOutlineIcon fontSize="large" style={{ color: green[500], fontSize: '4rem' }} />
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText align="center" style={{ marginTop: '10px' }}>
            <Typography variant="h5" style={{ color: green[500], fontWeight: 'bold',alignItems:'center',textAlign:'center' }}>
              Payment Successful!
            </Typography>
            Your payment was processed successfully. Thank you for your purchase!
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button onClick={handleClose} variant="contained" color="success" style={{ padding: '8px 20px' }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Payment Failed Dialog */}
      <Dialog open={failOpen} onClose={handleClose}>
        <DialogTitle>
          <Box display="flex" justifyContent="center">
            <ErrorOutlineIcon fontSize="large" style={{ color: red[500], fontSize: '4rem' }} />
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText align="center" style={{ marginTop: '10px' }}>
            <Typography variant="h5" style={{ color: red[500], fontWeight: 'bold',textAlign:'center' }}>
              Payment Failed!
            </Typography>
            Unfortunately, your payment could not be processed. Please try again or contact support.
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button onClick={handleClose} variant="contained" color="error" style={{ padding: '8px 20px' }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PaymentPopups;
