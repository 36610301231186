import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../FocusonPage/FocusOnPage.css";

const FocusOnPage = () => {
  const cardData = [
    {
      id: 1,
      imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS6p0pqvQpZCnP6wY00gPWYIFWeIqKG9mKBSw&s',
      title: 'UPTO 30% off',
      link: '#',
    },
    {
      id: 2,
      imageUrl: 'https://st2.depositphotos.com/6623886/10890/v/950/depositphotos_108909362-stock-illustration-super-sale-banner-design-you.jpg',
      title: 'UPTO 40% off',
      link: '#',
    },
    {
      id: 3,
      imageUrl: 'https://freedesignfile.com/upload/2022/11/Big-sale-banner-with-mega-discount-special-offer-vector.jpg',
      title: 'UPTO 50% off',
      link: '#',
    },
    {
      id: 4,
      imageUrl: 'https://freedesignfile.com/upload/2022/11/Big-sale-banner-with-mega-discount-special-offer-vector.jpg',
      title: 'UPTO 50% off',
      link: '#',
    },
    // Add more card objects as needed
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    arrows: true,
    draggable: true,
    swipe: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div class="focus-section">
        <h1>Focus On</h1>
        <p>Today's Standout labels</p>
      </div>

      <div className="container-focuson">

      <Slider className="unique-card-slider" {...settings}>
  {cardData.map((card, index) => (
    <div key={card.id} className="unique-main-focus-card">
      <div className="unique-card-container w-100" style={{ position: 'relative', margin: '0 15px' }}>
        <img
          className="unique-card-img-top"
          src={card.imageUrl}
          alt={`Card image cap ${index + 1}`}
        />
        <div className="unique-card-overlay">
          <h5 className="unique-card-title">{card.title}</h5>
          <a href={card.link} className="unique-btn-focus btn-primary">
            Shop Now
          </a>
        </div>
      </div>
    </div>
  ))}
</Slider>





      </div>
    </>
  );
};

export default FocusOnPage;
